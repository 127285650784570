body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2196f3;
  color: white;
}

.App {
  text-align: center;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.App-header {
  margin-bottom: 20px;
}

.App-header h1 {
  font-size: 24px;
  margin: 0;
}

.chat-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 200px); /* Adjust based on header and input form height */
}

.message {
  max-width: 80%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 20px;
  text-align: left;
  word-wrap: break-word;
}

.user {
  background-color: #64b5f6;
  align-self: flex-end;
  margin-left: auto;
}

.bot {
  background-color: #81c784;
  align-self: flex-start;
}

.input-form {
  display: flex;
  margin-top: auto;
}

input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-radius: 20px 0 0 20px;
  font-size: 16px;
}

button {
  padding: 10px 20px;
  border: none;
  background-color: #ff4081;
  color: white;
  border-radius: 0 20px 20px 0;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #f50057;
}

button:disabled {
  background-color: #bdbdbd;
  cursor: not-allowed;
}

@media (min-width: 768px) {
  .App {
    max-width: 600px;
  }
}